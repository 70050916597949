import React, { LegacyRef, SelectHTMLAttributes, useState } from 'react'
import styles from './index.module.scss'
import { v4 as uuidv4 } from 'uuid'
import InfoIcon from '../../../common/components/infoIcon/InfoIcon'
import { t } from '../../../common/i18n'
import Select, { components } from 'react-select'
import downArrowSvg from './down-arrow.svg'
import downArrowBlackSvg from './down-arrow-black.svg'

export type DropDownVariant = 'default' | 'sorter'

export interface DropDownOption {
  value: string | string[] | number
  label: string
}

export interface OptionGrouped {
  label: string
  options: DropDownOption[]
}

export type Props = {
  options: DropDownOption[] | OptionGrouped[]
  labelId?: string
  labelAbove?: boolean
  size?: 'normal' | 'big' | 'small' | 'xl' | 'xxl' | 'fullwidth'
  infoIconTitle?: string
  infoIconText?: string
  disabled?: boolean
  searchable?: boolean
  clearable?: boolean
  defaultSelected?: string | number | null | DropDownOption
  formRef?: LegacyRef<HTMLSelectElement>
  variant?: DropDownVariant
  onChange?: ({
    value,
    name,
  }: {
    value: string | number | string[]
    name: string
  }) => void
} & Omit<SelectHTMLAttributes<HTMLSelectElement>, 'size'>

const DropDown = ({
  variant = 'default',
  options,
  labelId,
  labelAbove,
  size = 'normal',
  infoIconTitle,
  infoIconText,
  formRef,
  defaultSelected,
  disabled,
  searchable,
  clearable = true,
  onChange,
  ...props
}: Props) => {
  const [_id] = useState(
    props.id
      ? props.id
      : labelId
      ? labelId.replace(/[^0-9a-z]/gi, '-')
      : `unknown-dropdown-${uuidv4()}`
  )
  const className = labelId
    ? labelAbove
      ? styles[`label_above_${size}`]
      : styles[`label_left_${size}`]
    : disabled
    ? styles[`default_${size}_disabled`]
    : styles[`default_${size}`]

  const formatDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <div className={styles.dropdownIndicator}>
        <img
          src={variant === 'sorter' ? downArrowBlackSvg : downArrowSvg}
          alt="Down arrow"
        />
      </div>
    </components.DropdownIndicator>
  )

  const handleChange = (selectedOption: DropDownOption) => {
    if (onChange && selectedOption) {
      onChange({ value: selectedOption.value, name: props.name }) // Include the name prop in the callback
    }
  }

  return (
    <div className={styles.dropdown_container}>
      {!!labelId && !!labelId.trim() && (
        <label htmlFor={_id} className={styles.label}>
          {t(labelId)}
          {(infoIconTitle || infoIconText) && (
            <InfoIcon title={infoIconTitle} text={infoIconText} />
          )}
        </label>
      )}
      <Select
        className={`${className} ${
          variant === 'sorter' ? styles.default_sorter : ''
        }`}
        id={_id}
        ref={formRef}
        disabled={disabled}
        placeholder={props.placeholder}
        defaultValue={defaultSelected}
        options={options}
        components={{
          DropdownIndicator: formatDropdownIndicator,
          IndicatorSeparator: () => null,
        }}
        isDisabled={disabled}
        isSearchable={searchable}
        isClearable={clearable}
        onChange={handleChange}
        {...props}
      />
    </div>
  )
}

export default DropDown
